import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const INDUSTRYSECTION = () => {   
    const data = useStaticQuery(graphql`
    query {
        UI {
            dsHome {
                IndustrySection {
                  Industry_BgColor
                  Industry_Heading
                  IndustryText
                  CTA_Name
                  CTA_URL
                  IndustryLists {
                    IndustryTitle
                    IndustryDescription
                  }
                }
            }    
        }
    }`)
    const industry = data.UI.dsHome.IndustrySection    
    const industryOne = data.UI.dsHome.IndustrySection.IndustryLists.slice(data.UI.dsHome.IndustrySection.IndustryLists.length-6, data.UI.dsHome.IndustrySection.IndustryLists.length-3)
    const industryTwo = data.UI.dsHome.IndustrySection.IndustryLists.slice(data.UI.dsHome.IndustrySection.IndustryLists.length-3, data.UI.dsHome.IndustrySection.IndustryLists.length) 

    return(
        <section className="BG-F9FAFE sectionProwess tB-padd-70" style={{backgroundColor: `${industry.Industry_BgColor ? industry.Industry_BgColor : null  }`}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        {industry.Industry_Heading ? (<h2 className="h2Bluetitle">{industry.Industry_Heading}</h2>) : null}
                        {industry.IndustryText ? (
                            <div className="prowessContent">
                                <p dangerouslySetInnerHTML={{ __html: industry.IndustryText }} />
                            </div>
                        ) : null}
                        {industry.CTA_Name && industry.CTA_URL ? (
                            <div className="latestBlog-viewAll ds-hide">
                                <a href={industry.CTA_URL}>
                                    {industry.CTA_Name}
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </a>
                            </div>
                        ):null}
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6 col-prowess">
                                <div className="prowessInner">
                                    { industryOne && industryOne.map((list, i) => (
                                        <div className="prowessBlock" key={i}>
                                            {list.IndustryTitle ? (<h4>{list.IndustryTitle}</h4>) : null}
                                            {list.IndustryDescription ? ( 
                                                <p dangerouslySetInnerHTML={{ __html: list.IndustryDescription }} />
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-6 col-prowess">
                                <div className="prowessInner">
                                    { industryTwo && industryTwo.map((lists, i) => (
                                        <div className="prowessBlock" key={i}>
                                            {lists.IndustryTitle ? (<h4>{lists.IndustryTitle}</h4>) : null}
                                            {lists.IndustryDescription ? ( 
                                                <p dangerouslySetInnerHTML={{ __html: lists.IndustryDescription }} />
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>    
    )
}

export default INDUSTRYSECTION