import * as React from "react"
import CountUp from 'react-countup'
import Layout from "../components/layout"
import WhatWeDoSection from "../components/homeSection/whatWeDoBlock"
import OurWorkSection from "../components/homeSection/ourWorkBlock"
import IndustrySection from "../components/homeSection/industryProwessBlock"
import AwardsandRecognitions from "../components/AwardsandRecognitions"
import OurlatestPosts from "../components/sections/ourLatestPostsBlock"
import GetInTouch from "../components/GetInTouch"
import { graphql, Link } from "gatsby"
import Head from '../components/head'

const IndexPage = ({ data }) => {

  const Home = data.UI.dsHome
  
  return (
    <Layout>
      <Head SEO_Tags={Home.SeoTag} />
      {Home && Home.Banner.map((banner, i) => {
        return (
          <section className="BG-F9FAFE sectionMainHero tB-padd-50" style={{backgroundColor: `${banner.DS_BackgroundColor ? banner.DS_BackgroundColor : null  }`, backgroundImage: `${banner.DS_BackgroundImage ? banner.DS_BackgroundImage.url : null  }`}}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 main_hero_left_col" key={i}>
                  <div className="hero_content_left">
                    <div className="mainSlider_left">
                      {banner.DS_Text ? (
                        <div className="item" dangerouslySetInnerHTML={{ __html: banner.DS_Text }} />
                      ) : null}                      
                    </div>
                    {banner.DS_CTA_Link && banner.DS_CTA_Text ? (
                      <div className="main_sliderBtn">
                        <Link exact to={banner.DS_CTA_Link}>{banner.DS_CTA_Text}</Link>
                      </div>
                    ) : null}                     
                  </div>
                  <div id="slideset" className="hero_content_right hero_animated_text">
                      {banner.SlidingText && banner.SlidingText.map((list, i) => ( 
                        <div key={i}>
                          <h1 className="morphext">{list.DS_SlidingText}</h1>                
                        </div>
                      ))}                      
                  </div>            
                </div>
              </div>
            </div>
          </section>
        )
      })}
      
      {Home.CompleteWork ? (
        <section className="BG-F9FAFE completedWork tB-padd-50" style={{backgroundColor: `${Home.CompleteWork.BackgroundColor ? Home.CompleteWork.BackgroundColor : null  }`}}>
          <div className="container">
            <div className="completeWorkContainer">
              <div className="completeWork_left">
                <div className="dashed_horizontal_line"></div>
                <ul className="ul_completeWork numbers">
                  <li>
                    <div className="comp_digit">
                      <span className="value"><CountUp start={0} end={Home.CompleteWork.Year} duration={2} /></span>+
                    </div>
                    <div className="comp_txt">Year</div>
                  </li>
                  <li>
                    <div className="comp_digit">
                      <span className="value"><CountUp start={0} end={Home.CompleteWork.DigitalNatives} duration={2} /></span>+
                    </div>
                    <div className="comp_txt">Digital Natives</div>
                  </li>
                  <li>
                    <div className="comp_digit">
                      <span className="value"><CountUp start={0} end={Home.CompleteWork.Clients} duration={2} /></span>
                    </div>
                    <div className="comp_txt">Clients</div>
                  </li>
                  <li>
                    <div className="comp_digit">
                      <span className="value"><CountUp start={0} end={Home.CompleteWork.Location} duration={2} /></span>
                    </div>
                    <div className="comp_txt">Location</div>
                  </li>
                </ul>
              </div>
              <div className="completeWork_right">
                <div dangerouslySetInnerHTML={{ __html: Home.CompleteWork.Description }} />
              </div>
            </div>
          </div>
        </section>
      ) : null}    
      
      <WhatWeDoSection />
      <OurWorkSection />  
      <IndustrySection />
      <AwardsandRecognitions />
      <OurlatestPosts />
      <section className="sectionCTA">
        <GetInTouch />
      </section>
    </Layout>  
  )
}

export default IndexPage

export const query = graphql`
  query {
    UI {
      dsHome {
        Title
        Banner {
          DS_BackgroundColor
          DS_BackgroundImage {
            url
            alternativeText
          }
          DS_FontColor
          DS_Text
          DS_CTA_Text
          DS_CTA_Link
          SlidingText {
            DS_SlidingText
          }
        }
        CompleteWork {
          BackgroundColor
          Heading
          Year
          DigitalNatives
          Clients
          Location
          Description
        }
        SeoTag {
          Meta_Title
          Meta_Description
        }
      }    
    }
  }
`