exports.makeWorkSingleSlug = ({ createdAt, Slug }) => {
    //console.log({Slug});
    return `/casestudy/${Slug}`
}
exports.makeBlogSingleSlug = ({ createdAt, Slug }) => {
    //console.log({Slug});
    return `/blog/${Slug}`
}
exports.makeWorkCategorySlug = ({ createdAt, Slug }) => {
    //console.log({Slug});
    return `/casestudy/${Slug}`
}