import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { makeWorkSingleSlug } from "../../utils"

const OURWORKSECTION = () => {
    const data = useStaticQuery(graphql`
    query {
        UI {
            dsHome {
                OurWork {
                  OtherWork_BgColor
                  OtherWork_Title
                  ShortDescription
                  CTA_URL
                  CTA_Name
                  DS_Case_Studies(limit: 4, sort: "published_at:desc") {
                    Title
                    Slug
                    FeaturedImage {
                      url
                      alternativeText
                    }
                    ShortDescription
                    published_at
                  }
                }
            }   
        }
    }`)
    const ourWork = data.UI.dsHome.OurWork
    
    return(
        <section className="BG-F9FAFE sectionOurWork" style={{backgroundColor: `${ourWork.OtherWork_BgColor ? ourWork.OtherWork_BgColor : null  }`}}>
            <div className="container">
              {ourWork.OtherWork_Title ? (<h2 className="h2Bluetitle">{ourWork.OtherWork_Title}</h2>) : null}
              <div className="contentOurWork" data-sal="slide-right" style={{'--sal-duration':'1s', '--sal-delay':'0.30s'}}>
                  {ourWork.ShortDescription ? (
                      <p dangerouslySetInnerHTML={{ __html: ourWork.ShortDescription }} />                            
                  ):null}
                  {ourWork.CTA_Name && ourWork.CTA_URL ? (
                      <div className="latestBlog-viewAll">
                          <a href={ourWork.CTA_URL}>
                              {ourWork.CTA_Name}
                              <i className="fa fa-angle-right" aria-hidden="true"></i>
                          </a>
                      </div>
                  ):null}
              </div>
              <div className="row">
                { ourWork.DS_Case_Studies && ourWork.DS_Case_Studies.map((list, i) => ( 
                    <div className="col-md-6 col-sm-6 ourWork_col" key={i}>
                        <Link exact to={makeWorkSingleSlug(list)}>
                            <div className="ourworkColInner">
                                {list.FeaturedImage? (
                                    <img src={list.FeaturedImage.url} className="img-fluid" alt={list.FeaturedImage.alternativeText} />
                                ) : null}
                                {list.Title? (<h3>{list.Title}</h3>) :null}
                                {list.ShortDescription ? ( 
                                    <div className="ourWorkContent"> 
                                        <p dangerouslySetInnerHTML={{ __html: list.ShortDescription }} />
                                    </div>
                                ) : null}
                            </div>
                        </Link>
                    </div>
                ))}
              </div>
            </div>
        </section>
    )
}

export default OURWORKSECTION