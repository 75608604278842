import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { makeBlogSingleSlug } from "../../utils"

const OURLAESTBLOGSECTION = () => {
    const data = useStaticQuery(graphql`
    query {
        UI {
            dsBlogs(sort: "PublishDate:desc", limit: 4) {
                Title
                Slug
                PublishDate
                Description
                Featured_Image {
                  url
                  alternativeText
                }      
            }    
        }
    }`)
    const blog = data.UI.dsBlogs

    return(
        <section className="sectionLatestPost BG-F9FAFE">
            <div className="container container-modified">
                <h2 className="h2Bluetitle">Our latest Posts</h2>
                <div className="row">
                    {blog && blog.map((list, i) => ( 
                        <div className="col-md-6 col-latest-blog" key={i}>
                            <Link exact to={makeBlogSingleSlug(list)}>
                                <div className="latestBlogInner">
                                    <div className="latestBlogImg">
                                        {list.Featured_Image ? ( 
                                            <img src={list.Featured_Image.url} alt={list.Featured_Image.alternativeText} />
                                        ) : null}
                                    </div>
                                    <div className="latestBlogTxt">
                                        {list.Title ? (<h4>{list.Title}</h4>) : null}
                                        <div dangerouslySetInnerHTML={{ __html: `${list.Description.substring(0, '120')}...` }} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default OURLAESTBLOGSECTION
