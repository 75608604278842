import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const OURPROCESSection = () => {
	const data = useStaticQuery(graphql`
    query {
        UI {
            dsHome {
                OurProcess {
                  WhatWeDo_BgColor
                  WhatWeDo_Title
                  WhatWeDo_Description
                  Lists {
                    WhatWeDo_Text
                    WhatWeDo_Heading
                  }
                }
                OurProcessLogo {
                  Logo_Heading
                  LogoImage {
                    SingleImages {
                      url
                      alternativeText
                    }
                  }
                }
            }    
        }
    }`)
    const OurProcess = data.UI.dsHome.OurProcess
    const OurProcessLogo = data.UI.dsHome.OurProcessLogo

    return(
		<>  
            <div className="container">
                <div className="whatWedoLeftcol ourProcessLeftCol">
                    {OurProcess.WhatWeDo_Title ? (
                        <h2 className="h2Bluetitle">{OurProcess.WhatWeDo_Title}</h2>
                    ) : null}
                    {OurProcess.WhatWeDo_Description ? (
                        <div className="ourProcessContent" data-sal="slide-right" style={{'--sal-duration':'1s', '--sal-delay':'0.30s'}}>
                            <p dangerouslySetInnerHTML={{ __html: OurProcess.WhatWeDo_Description }} />
                        </div>
                    ) : null}
                    <div className="leftPaddColStruture">
                        { OurProcess.Lists && OurProcess.Lists.map((list, i) => (
                            <div key={i} data-sal="slide-up" className="whatWeDoBox" style={{'--sal-duration':'0.50s', '--sal-delay':'0.15s'}}>
                                {list.WhatWeDo_Heading ? (
                                    <h4 className="h2Bluetitle">{list.WhatWeDo_Heading}</h4>
                                ) : null}
                                {list.WhatWeDo_Text ? (
                                    <div dangerouslySetInnerHTML={{ __html: list.WhatWeDo_Text }} />
                                ) : null}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="whatWedoRightcol ourProcessRightCol">
                    {OurProcessLogo.Logo_Heading ? (
                        <h4 className="whatWeDo-rightColTitle">{OurProcessLogo.Logo_Heading}</h4>
                    ) : null}
                    <ul className="ulTrusted_logo">
                        {OurProcessLogo.LogoImage && OurProcessLogo.LogoImage.map((list, i) => (
                            <li key={i} data-sal="slide-down" style={{'--sal-duration': '1s', '--sal-delay':'0.15s'}}>
                                <img src={list.SingleImages.url} alt={list.SingleImages.alternativeText} /> {list.SingleImages.alternativeText}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>			
		</>				
    )
}

export default OURPROCESSection