import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const TRUSTEDLOGOSECTION = () => {
    const data = useStaticQuery(graphql`
    query {
        UI {
            dsHome {
                TrustedLogo {
                  Heading
                  MultipleImages {
                    MultipleImages {
                      url
                      alternativeText
                    }
                  }
                  SingleImage {
                    SingleImages {
                      url
                      alternativeText
                    }
                  }
                }
            }    
        }
    }`)
    const trusted = data.UI.dsHome.TrustedLogo
    
    return(
        <>
            {trusted.Heading ? (
                <h4 className="whatWeDo-rightColTitle">{trusted.Heading}</h4>
            ) : null}  
            {trusted.MultipleImages ? (
                <div className="logo-slides">
                    <ul className="ulTrusted_logo">
                        {trusted.MultipleImages && trusted.MultipleImages.map((lists, i) => ( 
                            <li className={`d${i}`} key={i}>
                                { lists.MultipleImages.map((list, i) => (
                                    <img src={list.url} alt={list.alternativeText} className="ls lg " key={i} />
                                ))}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}  
            {trusted.SingleImage ? (
                <ul className="ulTrusted_logo pt-0">
                    {trusted.SingleImage && trusted.SingleImage.map((singlelists, i) => ( 
                        <li className={`d${i}`} key={i} data-sal="slide-up" style={{'--sal-duration':'0.50s', '--sal-delay':'0.15s'}}>
                            <img src={singlelists.SingleImages.url} alt={singlelists.SingleImages.alternativeText}  />
                        </li>
                    ))}
                </ul>
            ) : null}         
        </>
    )
}

export default TRUSTEDLOGOSECTION