import * as React from "react"
import { Link } from "gatsby"

const LetsContact = () => {
    return(
        <div className="container container-modified">
            <div className="ctaInner">
                <h2>Let’s Make Something Great together! </h2>
                <div className="sectionCTA_btn">
                    <Link exact to="/contact" rel="noreferrer">Get In Touch</Link>
                </div>
            </div>
        </div>
    )
}

export default LetsContact