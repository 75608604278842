import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const AwardsandRecognitions = () => {

    const data = useStaticQuery(graphql`
    query {
        UI {
            dsHome {
                Awards_Recognitions {
                  Award_BgColor
                  Award_Heading
                  Award_Text
                  AwardsLists {
                    AwardsListTitle
                    AwardsListText
                    AwardsListImage {
                      url
                      alternativeText
                    }
                  }
                }
            }    
        }
    }`)
    const awards = data.UI.dsHome.Awards_Recognitions
    const awardsOne = data.UI.dsHome.Awards_Recognitions.AwardsLists.slice(data.UI.dsHome.Awards_Recognitions.AwardsLists.length-12, data.UI.dsHome.Awards_Recognitions.AwardsLists.length-9)
    const awardsTwo = data.UI.dsHome.Awards_Recognitions.AwardsLists.slice(data.UI.dsHome.Awards_Recognitions.AwardsLists.length-9, data.UI.dsHome.Awards_Recognitions.AwardsLists.length-6)
    const awardsThree = data.UI.dsHome.Awards_Recognitions.AwardsLists.slice(data.UI.dsHome.Awards_Recognitions.AwardsLists.length-6, data.UI.dsHome.Awards_Recognitions.AwardsLists.length-3)
    const awardsfour = data.UI.dsHome.Awards_Recognitions.AwardsLists.slice(data.UI.dsHome.Awards_Recognitions.AwardsLists.length-3, data.UI.dsHome.Awards_Recognitions.AwardsLists.length)

    return(
        <section className="sectionAward tB-padd-70" style={{backgroundColor: `${awards.Award_BgColor ? awards.Award_BgColor : null  }`}}>
            <div className="container">
                {awards.Award_Heading ? (<h2 className="h2Bluetitle">{awards.Award_Heading}</h2>) : null}
                <div className="award-container">
                    <div className="awardRow logo-slides">
                        <div className="awardCols">
                            { awardsOne && awardsOne.map((list, i) => (
                                <div className="ls lg awardContPos row" key={i}>
                                    <div className="col-md-3">
                                        {list.AwardsListTitle ? (
                                            <div className="textContainer ">{list.AwardsListTitle}</div>
                                        ) : null}                                        
                                    </div>
                                    <div className="col-md-6">
                                        {list.AwardsListText ? (
                                            <div className="text-center" dangerouslySetInnerHTML={{ __html: list.AwardsListText }} />
                                        ) : null}    
                                    </div>
                                    <div className="col-md-3">
                                        {list.AwardsListImage ? (
                                            <div className="text-center">
                                                <img src={list.AwardsListImage.url} alt={list.AwardsListImage.alternativeText} className="img-award" />
                                            </div>    
                                        ) : null}    
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="awardCols d2">
                            { awardsTwo && awardsTwo.map((listTwo, i) => (
                                <div className="ls lg awardContPos row" key={i}>
                                    <div className="col-md-3">
                                        {listTwo.AwardsListTitle ? (
                                            <div className="textContainer ">{listTwo.AwardsListTitle}</div>
                                        ) : null}                                        
                                    </div>
                                    <div className="col-md-6">
                                        {listTwo.AwardsListText ? (
                                            <div className="text-center" dangerouslySetInnerHTML={{ __html: listTwo.AwardsListText }} />
                                        ) : null}    
                                    </div>
                                    <div className="col-md-3">
                                        {listTwo.AwardsListImage ? (
                                            <div className="text-center">
                                                <img src={listTwo.AwardsListImage.url} alt={listTwo.AwardsListImage.alternativeText} className="img-award" />
                                            </div>
                                        ) : null}    
                                    </div>
                                </div>
                            ))}
                        </div>  

                        <div className="awardCols">
                            { awardsThree && awardsThree.map((listThree, i) => (
                                <div className="ls lg awardContPos row" key={i}>
                                    <div className="col-md-3">
                                        {listThree.AwardsListTitle ? (
                                            <div className="textContainer ">{listThree.AwardsListTitle}</div>
                                        ) : null}                                        
                                    </div>
                                    <div className="col-md-6">
                                        {listThree.AwardsListText ? (
                                            <div className="text-center" dangerouslySetInnerHTML={{ __html: listThree.AwardsListText }} />
                                        ) : null}    
                                    </div>
                                    <div className="col-md-3">
                                        {listThree.AwardsListImage ? (
                                            <div className="text-center">
                                                <img src={listThree.AwardsListImage.url} alt={listThree.AwardsListImage.alternativeText} className="img-award" />
                                            </div>
                                        ) : null}    
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="awardCols d2">
                            { awardsfour && awardsfour.map((listFour, i) => (
                                <div className="ls lg awardContPos row" key={i}>
                                    <div className="col-md-3">
                                        {listFour.AwardsListTitle ? (
                                            <div className="textContainer ">{listFour.AwardsListTitle}</div>
                                        ) : null}                                        
                                    </div>
                                    <div className="col-md-6">
                                        {listFour.AwardsListText ? (
                                            <div className="text-center" dangerouslySetInnerHTML={{ __html: listFour.AwardsListText }} />
                                        ) : null}    
                                    </div>
                                    <div className="col-md-3">
                                        {listFour.AwardsListImage ? (
                                            <div className="text-center">
                                                <img src={listFour.AwardsListImage.url} alt={listFour.AwardsListImage.alternativeText} className="img-award" />
                                            </div>                                            
                                        ) : null}    
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                    </div>
                </div> 
            </div>
        </section>
    )
}

export default AwardsandRecognitions