import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import TrustedLogoSection from "./trustedLogoBlock"
import OurProcessSection from "./ourProcessBlock"

const WhatweDo = () => {
	const data = useStaticQuery(graphql`
    query {
        UI {
            dsHome {
				WhatweDO {
				  WhatWeDo_BgColor
				  WhatWeDo_Title
				  WhatWeDo_Description
				  Lists {
					WhatWeDo_Text
					WhatWeDo_Heading
				  }
				}
			}    
        }
    }`)
    const whatwedo = data.UI.dsHome.WhatweDO
	
    return(
		<>
			<section className="tB-padd-70 sectionWhatWeDo" style={{backgroundColor: `${whatwedo.WhatWeDo_BgColor ? whatwedo.WhatWeDo_BgColor : null  }`}}>
				<div className="container">
					<div className="whatWedoLeftcol">
						{whatwedo.WhatWeDo_Title ? (
							<h2 className="h2Bluetitle">{whatwedo.WhatWeDo_Title}</h2>
						) : null}
						{whatwedo.WhatWeDo_Description ? (
							<div className="ourProcessContent" data-sal="slide-right" style={{'--sal-duration':'1s', '--sal-delay':'0.30s'}}>
								<p dangerouslySetInnerHTML={{ __html: whatwedo.WhatWeDo_Description }} />
							</div>
						) : null}
						<div className="leftPaddColStruture">
							{ whatwedo.Lists && whatwedo.Lists.map((list, i) => (
								<div key={i} data-sal="slide-up" className="whatWeDoBox" style={{'--sal-duration':'0.50s', '--sal-delay':'0.15s'}}>
									{list.WhatWeDo_Heading ? (
										<h4 className="h2Bluetitle">{list.WhatWeDo_Heading}</h4>
									) : null}
									{list.WhatWeDo_Text ? (
										<div dangerouslySetInnerHTML={{ __html: list.WhatWeDo_Text }} />
									) : null}
								</div>
							))}
						</div>
					</div>	
					<div className="whatWedoRightcol">
						<TrustedLogoSection />
					</div>
				</div>
				<OurProcessSection />
			</section>
		</>				
    )
}

export default WhatweDo